import { IPhone } from "@smartrr/shared/entities/Phone";

import { SmartrrThunkAction } from "@vendor-app/app/_state/typedVendorRedux";
import { vendorAsyncDispatch } from "@vendor-app/app/_state/vendorAsyncDispatch";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export const loadPhone =
  (): SmartrrThunkAction<"LOADING_PHONE" | "ERROR_LOADING_PHONE" | "LOADED_PHONE"> => dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.getReq("/phone"),
      () =>
        dispatch({
          type: "LOADING_PHONE",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_LOADING_PHONE",
          payload: {
            errorMessage: failure.message,
          },
        }),
      phone =>
        dispatch({
          type: "LOADED_PHONE",
          payload: {
            phone,
          },
        })
    );

export const updatePhone =
  ({
    updatedPhoneProperties,
  }: {
    updatedPhoneProperties: Partial<IPhone>;
  }): SmartrrThunkAction<"UPDATING_PHONE" | "ERROR_UPDATING_PHONE" | "UPDATED_PHONE"> =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.postReq("/phone", {
        reqBody: {
          updatedPhoneProperties,
        },
      }),
      () =>
        dispatch({
          type: "UPDATING_PHONE",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_UPDATING_PHONE",
          payload: {
            errorMessage: failure.message,
          },
        }),
      phone =>
        dispatch({
          type: "UPDATED_PHONE",
          payload: {
            phone,
          },
        })
    );
