import React from "react";
import { useEmails } from "../hooks/useEmails";
import { Card, Text, VerticalStack } from "@shopify/polaris";
import { ArpuIntegrationConfigRow, EmailConfigRowOption } from "./EmailConfigRows";
import { NotificationEmails as NotificationEmailsEnum } from "@smartrr/shared/entities/CustomerEmailConfig";
import { NotificationEmails } from "@smartrr/shared/entities/CustomerEmailConfig/schemas";

export interface EmailSectionCardsProps {
  emailValues: NotificationEmails.Type;
  rowActions: {
    onRowClick: (emailType: NotificationEmailsEnum) => void;
    onEmailToggle: (toggleValue: keyof NotificationEmails.Type, checked: boolean) => void;
  };
}

export const EmailSectionCards = ({ emailValues, rowActions }: EmailSectionCardsProps) => {
  const { formattedSectionsWithEmails } = useEmails({ defaults: false, emailRecipient: "customer" });

  return (
    <React.Fragment>
      {formattedSectionsWithEmails.map(({ section, emails }) => {
        return (
          <Card key={section.title}>
            <VerticalStack gap={"5"}>
              {/* Upper */}
              <VerticalStack>
                <Text as="h3" variant="headingMd" fontWeight="semibold">
                  {section.title}
                </Text>
                {section.description ? (
                  <Text as="h3" variant="bodySm" fontWeight="regular">
                    {section.description}
                  </Text>
                ) : null}
              </VerticalStack>
              {/* Inner */}
              <VerticalStack gap="5">
                {emails.map(emailObj => {
                  const [emailType, emailConfig] = Object.entries(emailObj)[0];

                  const isDisabledByUpcomingOrderEmail = emailConfig.conditional
                    ? !emailValues.sendUpcomingOrderEmail && !emailValues.isArpuEnabled
                    : false;
                  return (
                    <React.Fragment key={emailType}>
                      <EmailConfigRowOption
                        attributes={emailConfig}
                        emailType={emailType as NotificationEmailsEnum}
                        onClick={rowActions.onRowClick}
                        onEmailToggle={rowActions.onEmailToggle}
                        disabled={isDisabledByUpcomingOrderEmail}
                      />
                      {emailType === NotificationEmailsEnum.SUBSCRIPTION_UPCOMING_ORDER ? (
                        <ArpuIntegrationConfigRow
                          onEmailToggle={rowActions.onEmailToggle}
                          enabled={emailValues.isArpuEnabled ?? false}
                        />
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </VerticalStack>
            </VerticalStack>
          </Card>
        );
      })}
    </React.Fragment>
  );
};
