/* -------------------------------- Libraries ------------------------------- */
import { FormLayout, LegacyCard } from "@shopify/polaris";
import { NotificationEmails } from "@smartrr/shared/entities/CustomerEmailConfig";
import React from "react";

/* ------------------------------- Components ------------------------------- */
import { VariableCard } from "./VariableCard";
import {
  AccountCreationReminderVariables,
  BonusEarnReminderVariables,
  CustomerVariables,
  FromVariables,
  ItemOOSVariables,
  LineVariables,
  NoteVariables,
  OtpCodeRedemptionVariables,
  PasswordlessVariables,
  ReferralProgramVariables,
  RewardsReminderVariables,
  SubscriptionVariables,
  ToVariables,
} from "../constants";
import { VariableCardsWrapper } from "../styles";

const subscriptionRelatedEmails: NotificationEmails[] = [
  NotificationEmails.SUBSCRIPTION_UPCOMING_ORDER,
  NotificationEmails.SUBSCRIPTION_PAUSE,
  NotificationEmails.SUBSCRIPTION_AUTO_RESUME,
  NotificationEmails.SUBSCRIPTION_GIFT_GIFTER,
  NotificationEmails.SUBSCRIPTION_GIFT_GIFTEE,
  NotificationEmails.SUBSCRIPTION_GIFT_CREATE,
  NotificationEmails.SUBSCRIPTION_FAILED_PAYMENT,
  NotificationEmails.SUBSCRIPTION_CREATE_SIGNUP,
  NotificationEmails.SUBSCRIPTION_CREATE,
  NotificationEmails.SUBSCRIPTION_CANCEL,
  NotificationEmails.SUBSCRIPTION_ACTIVATE,
];

export const VariablesTable = ({ currentEmailType }: { currentEmailType: NotificationEmails }): JSX.Element => {
  return (
    <VariableCardsWrapper>
      <LegacyCard sectioned title="Variables">
        <FormLayout>
          <FormLayout.Group
            condensed
            helpText="Smartrr provides a number of variables that can be accessed within an email template. The following fields are accessible for all email templates. Click on a variable to copy it to your clipboard:"
          />
        </FormLayout>
      </LegacyCard>
      <VariableCard
        codeSnippet="subscription.VARIABLE"
        currentEmailType={currentEmailType}
        rows={SubscriptionVariables}
        showOnEmailTypes={[...subscriptionRelatedEmails]}
        title="Subscription"
      />
      <VariableCard
        codeSnippet="subscription.lines[0].VARIABLE"
        currentEmailType={currentEmailType}
        rows={LineVariables}
        showOnEmailTypes={[...subscriptionRelatedEmails]}
        title="Subscription"
      />
      <VariableCard
        codeSnippet="customer.VARIABLE"
        currentEmailType={currentEmailType}
        rows={CustomerVariables}
        showOnEmailTypes={[
          ...subscriptionRelatedEmails,
          NotificationEmails.ITEM_OUT_OF_STOCK_CHECK,
          NotificationEmails.ONE_TIME_CODE,
          NotificationEmails.MAGIC_LINK,
        ]}
        title="Customer"
      />

      <VariableCard
        codeSnippet="note"
        currentEmailType={currentEmailType}
        rows={NoteVariables}
        showOnEmailTypes={[
          NotificationEmails.SUBSCRIPTION_GIFT_CREATE,
          NotificationEmails.SUBSCRIPTION_GIFT_GIFTEE,
          NotificationEmails.SUBSCRIPTION_GIFT_GIFTER,
        ]}
        title="Note"
      />
      <VariableCard
        codeSnippet="to.VARIABLE"
        currentEmailType={currentEmailType}
        rows={ToVariables}
        showOnEmailTypes={[
          NotificationEmails.SUBSCRIPTION_GIFT_CREATE,
          NotificationEmails.SUBSCRIPTION_GIFT_GIFTEE,
          NotificationEmails.SUBSCRIPTION_GIFT_GIFTER,
        ]}
        title="To"
      />
      <VariableCard
        codeSnippet="from.VARIABLE"
        currentEmailType={currentEmailType}
        rows={FromVariables}
        showOnEmailTypes={[
          NotificationEmails.SUBSCRIPTION_GIFT_CREATE,
          NotificationEmails.SUBSCRIPTION_GIFT_GIFTEE,
          NotificationEmails.SUBSCRIPTION_GIFT_GIFTER,
        ]}
        title="From"
      />
      <VariableCard
        codeSnippet="outOfStockItem.VARIABLE"
        currentEmailType={currentEmailType}
        rows={ItemOOSVariables}
        showOnEmailTypes={[
          NotificationEmails.ITEM_OUT_OF_STOCK_CHECK,
          NotificationEmails.OUT_OF_STOCK_REWARD_ITEM,
        ]}
        title="Item out of Stock"
      />

      <VariableCard
        codeSnippet="passwordless.VARIABLE"
        currentEmailType={currentEmailType}
        rows={PasswordlessVariables}
        showOnEmailTypes={[NotificationEmails.ONE_TIME_CODE, NotificationEmails.MAGIC_LINK]}
        title="Passwordless Login"
      />
      <VariableCard
        codeSnippet="referrals.VARIABLE"
        currentEmailType={currentEmailType}
        rows={ReferralProgramVariables}
        showOnEmailTypes={[NotificationEmails.SUCCESSFUL_REFERRAL]}
        title="Loyalty and referrals"
      />
      <VariableCard
        codeSnippet="accountCreationReminder.VARIABLE"
        currentEmailType={currentEmailType}
        rows={AccountCreationReminderVariables}
        showOnEmailTypes={[NotificationEmails.ACCOUNT_CREATION_REMINDER]}
        title="Loyalty and referrals"
      />
      <VariableCard
        codeSnippet="bonusEarnedReminder.VARIABLE"
        currentEmailType={currentEmailType}
        rows={BonusEarnReminderVariables}
        showOnEmailTypes={[NotificationEmails.LOYALTY_BONUS_EARNED]}
        title="Loyalty and referrals"
      />
      <VariableCard
        codeSnippet="otpRedemption.VARIABLE"
        currentEmailType={currentEmailType}
        rows={OtpCodeRedemptionVariables}
        showOnEmailTypes={[NotificationEmails.LOYALTY_OTP_REDEMPTION]}
        title="Loyalty and referrals"
      />
      <VariableCard
        codeSnippet="rewardsReminder.VARIABLE"
        currentEmailType={currentEmailType}
        rows={RewardsReminderVariables}
        showOnEmailTypes={[NotificationEmails.LOYALTY_REWARDS_REMINDER]}
        title="Loyalty and referrals"
      />
    </VariableCardsWrapper>
  );
};
