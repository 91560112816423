import { DataTable, LegacyCard, TableData, Text } from "@shopify/polaris";
import { NotificationEmails } from "@smartrr/shared/entities/CustomerEmailConfig";
import React from "react";

import { CodeSnippet } from "../styles";

interface IVariableCardProps {
  /** Data rows */
  rows: TableData[][];
  /** Snippet that is displayed for the user to reference */
  codeSnippet: string;
  /** Title of variable list */
  title: string;
  /**  Which emails should these variables be visible on */
  showOnEmailTypes: NotificationEmails[];
  /** Email that the user has currently navigated to */
  currentEmailType: NotificationEmails;
}

export const VariableCard = ({
  codeSnippet,
  rows,
  title,
  showOnEmailTypes,
  currentEmailType,
}: IVariableCardProps) => {
  if (!showOnEmailTypes.includes(currentEmailType)) {
    return <React.Fragment />;
  }

  return (
    <LegacyCard>
      <div className="Polaris-Card__Header">
        <Text variant="headingMd" as="h2">
          {title}
        </Text>

        <CodeSnippet>&lt;%- {codeSnippet} %&gt;</CodeSnippet>
      </div>
      <DataTable columnContentTypes={["text", "text"]} headings={["Variable", "Description"]} rows={rows} />
    </LegacyCard>
  );
};
