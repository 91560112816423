import { FormLayout, LegacyCard, Spinner, Text } from "@shopify/polaris";
import React, { useCallback, useEffect } from "react";
import styled from "styled-components";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useTypedForm } from "@vendor-app/app/_sharedComponents/TypedForm/useTypedForm";
import { loadPhone, updatePhone } from "@vendor-app/app/_state/actionCreators/phone";
import { useActiveOrganizationIdSelector } from "@vendor-app/app/_state/reducers/organizations";
import "./styles.css";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { formatPhone } from "./formatPhone";
import { openTwilioConnectWindow } from "./openTwilioConnectWindow";
import { SmsAddons } from "./SmsAddons";

import { IPhoneForm, customerPhoneValidationSchema } from "@smartrr/shared/entities/Phone";
import { Box } from "@mui/material";

const FormWrapper = styled(Box)<{ toggled: boolean }>`
  div.auth-twilio-upcoming-inner {
    .Polaris-FormLayout__Item {
      margin-top: 0px;
    }
    div.switch-wrapper {
      position: relative;

      align-items: center;
      color: ${({ toggled }) => (toggled ? "#2877CC" : "var(--p-color-text-disabled)")};

      & span.Polaris-Text {
        width: 21px;
      }

      .switch-inner {
        align-items: center;
        display: flex;
        flex-direction: row;
        position: absolute;
        right: 0px;
        gap: 5px;
        width: 84px;
      }
    }
    .Polaris-Box {
      width: 467px;
    }
  }
`;
const AccountStatus = styled.p`
  color: var(--p-color-text-subdued);
  margin-top: -15px;
  padding-bottom: 12px;
`;

const SMS_ADDONS_ENABLED = false;

export function SmsForm(): JSX.Element {
  const dispatch = useSmartrrVendorDispatch();
  const phoneIsInitializing = useSmartrrVendorSelector(state => state.phone.isInitializing);
  const phone = useSmartrrVendorSelector(state => state.phone.phone);
  const activeOrganizationId = useActiveOrganizationIdSelector();

  const { addToast } = useToast();

  useEffect(() => {
    dispatch(loadPhone());
  }, []);

  const onOpenTwilioConnect = useCallback(() => {
    if (!activeOrganizationId) {
      return;
    }

    openTwilioConnectWindow(activeOrganizationId)
      .then(() => {
        dispatch(loadPhone());
      })
      .catch(error => {
        addToast("Error connecting Smartrr to Twilio.");
        console.error(error);
      });
  }, [activeOrganizationId]);

  const onPhoneConfigSave = useCallback(
    async (formValues: IPhoneForm) => {
      if (!activeOrganizationId) {
        return;
      }

      await dispatch(
        updatePhone({
          updatedPhoneProperties: formValues,
        })
      );

      addToast("SMS config updated");
    },
    [activeOrganizationId]
  );

  const phoneFormDefaultValues: IPhoneForm = {
    sendUpcomingOrderSms: false,
  };
  const initialValues: IPhoneForm = {
    ...phoneFormDefaultValues,
    ...phone,
  };

  const { useField, useValues } = useTypedForm<IPhoneForm>({
    initialValues,
    validationSchema: customerPhoneValidationSchema,
    validateOnChange: true,
    onSubmit: onPhoneConfigSave,
    enableReinitialize: true,
  });

  const formValues = useValues();

  const { Radio: SendUpcomingOrderSMSCheckbox } = useField("sendUpcomingOrderSms");

  if (phoneIsInitializing) {
    return <Spinner />;
  }

  const toggleSwitchButtons = [
    {
      label: "ON",
      disabled: false,
      value: true,
    },
    {
      label: "OFF",
      disabled: false,
      value: false,
    },
  ];

  return phone ? (
    <FormWrapper toggled={formValues.sendUpcomingOrderSms}>
      <LegacyCard sectioned title="Twilio Connection" footerActionAlignment="right">
        <AccountStatus>Account connected</AccountStatus>
        <p>
          Phone number: <span style={{ fontWeight: "bold" }}>{formatPhone(phone.phoneNumber)}</span>
        </p>
        <p style={{ paddingTop: "2px" }}>
          To deauthorize the Twilio x Smartrr connection, visit your{" "}
          <a
            href="https://www.twilio.com/console/usage/authorized-app"
            style={{ textDecoration: "underline" }}
            target="_blank"
            rel="noreferrer"
          >
            Twilio authorized apps console
          </a>
          .
        </p>
      </LegacyCard>
      <LegacyCard sectioned title="Upcoming subscription">
        <div className="auth-twilio-upcoming-inner">
          <FormLayout>
            <FormLayout.Group
              condensed
              helpText="Sent 3 days before a recurring subscription order is scheduled to be placed."
            >
              <div className="switch-wrapper">
                <div className="switch-inner">
                  <SendUpcomingOrderSMSCheckbox
                    name="radio"
                    align="right"
                    buttons={toggleSwitchButtons}
                    onToggle={() => onPhoneConfigSave(formValues)}
                    useMui
                  />
                  <Text as="span" variant="bodyMd">
                    {formValues.sendUpcomingOrderSms ? "On" : "Off"}
                  </Text>
                </div>
              </div>
            </FormLayout.Group>
          </FormLayout>
        </div>
      </LegacyCard>
      {!!SMS_ADDONS_ENABLED && <SmsAddons />}
    </FormWrapper>
  ) : (
    <LegacyCard
      sectioned
      title="Connect Twilio account"
      footerActionAlignment="left"
      secondaryFooterActions={[{ content: "Connect Twilio", onAction: onOpenTwilioConnect }]}
    >
      <FormLayout>
        <FormLayout.Group condensed helpText="You will be redirected to another page to authorize Twilio." />
      </FormLayout>
    </LegacyCard>
  );
}
