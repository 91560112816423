import { NotificationEmails, EmailSectionsEnum } from ".";
import { CurrencyCode } from "../../currencyCode";
import { RequireNilFields } from "../../utils/RequireNilFields";
import { ICustomerRelationship } from "../CustomerRelationship";
import { IPurchasable } from "../Purchasable";
import { IPurchasableVariant } from "../PurchasableVariant";
import { IPurchaseState } from "../PurchaseState";
import { ISharedEntityFields } from "../shared/SharedEntityFields";

export type ICustomerEmailConfigForm = RequireNilFields<ICustomerEmailConfig>;

export type IFormValues =
  | ICustomerEmailConfig
  | RequireNilFields<ICustomerEmailConfig>
  | ICustomerEmailTheming
  | RequireNilFields<ICustomerEmailTheming>
  | undefined;

//TODO: Rename 'both' to 'all'
export type EmailRecipientType = "customer" | "merchant" | "both";

export interface IEmailPreview {
  title: string;
  description: string;
  caption?: string;
  section: EmailSectionsEnum;
  enabled?: boolean;
  preview?: boolean;
  emailBody?: string;
  emailSubject?: string;
  emailRecipient: EmailRecipientType;
  /** Is email sent only if a certain condition is met? */
  conditional: boolean;
  hideToggle?: boolean;

  /** true - hidden to all users */
  hidden?: boolean;
  /** TEMP - Will be removed post refactor
   *  Needed to prevent double header and footers being added to emails **/
  disableHeaderAndFooter?: boolean;
  /** Corresponding migration values */
  configValues?: {
    toggle?: keyof ICustomerEmailConfig;
    subject?: keyof ICustomerEmailConfig;
    body?: keyof ICustomerEmailConfig;
    bccAddress?: keyof ICustomerEmailConfig;
    sendBccAddress?: keyof ICustomerEmailConfig;
  };
}

export interface ICustomerEmailPageProps {
  setView: React.Dispatch<
    React.SetStateAction<{
      view: CustomerNotificationViews;
      emailType?: NotificationEmails | undefined;
    }>
  >;

  areValidChanges: (formValues: RequireNilFields<ICustomerEmailConfig>, emailConfig: IEmailPreview) => boolean;
}

export interface IConfigRowOptionsProps {
  emailType: NotificationEmails;
  onClick: (emailType: NotificationEmails) => void;
  attributes: IEmailPreview;
  onEmailToggle: (toggleValue: keyof ICustomerEmailConfig, checked: boolean) => void;
  disabled: boolean;
}

export interface ICustomerEmailConfigDb extends ICustomerEmailConfig, ISharedEntityFields {}

export interface EmailTemplateCustomer {
  id: ICustomerRelationship["shopifyId"];
  firstName: ICustomerRelationship["firstName"];
  lastName: ICustomerRelationship["lastName"];
  email: ICustomerRelationship["email"];
  phone: ICustomerRelationship["phone"];
}

export interface EmailTemplateReferralProgram {
  description: string;
  rewardUnitNameSingular?: string;
  rewardUnitNamePlural?: string;
}

export interface EmailTemplateSubscription {
  id: string;
  lines: EmailTemplateSubscriptionLine[];
  nextBillingDate: IPurchaseState["nextBillingDate"];
  unpauseDate: IPurchaseState["unpauseDate"];
  daysTillUnpause: number;
  currency: CurrencyCode;
  totalEstimatedNet: string;
  totalDiscount: string;
  totalDiscountForUpcomingOrder: string;
  totalFromLineItems: string;
  totalLineItemsDiscount: string;
  totalShipping: string;
  totalShippingDiscount: string;
  cardType: string;
  deliveryDate: string;
  daysTillDelivery: number;
  orderNote: string;
}
export interface EmailTemplateSubscriptionLine {
  id: string;
  basePrice: string;
  isAddOn: boolean;
  priceAfterDiscounts: string;
  quantity: number;
  product: EmailTemplateProduct;
  variant: EmailTemplateVariant;
}

interface EmailTemplateProduct {
  id: string;
  name: IPurchasable["purchasableName"];
}

interface EmailTemplateVariant {
  id: string;
  name: IPurchasableVariant["purchasableVariantName"];
  image?: string;
}

export enum CustomerNotificationViews {
  DEFAULT,
  EDIT_TEMPLATES,
  EDIT_EMAIL,
}

export interface EmailTemplateOoSItem {
  id: string;
  name: string;
  variantName: string;
  image: string | undefined;
  unavailableReason: string;
}

export interface EmailTemplateFailedPayment {
  reason: string;
}

export interface EmailTemplateLoyaltyItem {
  itemType: "product" | "incentive";
  costInPoints: number;
  productName: string;
  imageUrl: string;
  imageText: string;
  imageColor: string;
}

export interface ICustomerEmailTheming {
  logo: string;
  logoWidth: number | string;
  useHeaderAndFooter: boolean;
  useStyling: boolean;
  colors: ICustomerEmailThemingColors;
}

export interface ICustomerEmailThemingColors {
  backgroundColor: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  buttonBackgroundColor: string;
  buttonTextColor: string;
}

export interface ICustomerEmailPasswordless {
  loginUrl?: string;
  oneTimeCode?: string;
  expirationLength: number;
}

// TODO: deprecate interface in favor of zod schema
export interface ICustomerEmailConfig {
  sendSubscriptionCreateSignupEmail?: boolean;
  sendSubscriptionCreateSignupEmailBcc?: boolean;
  subscriptionCreateSignupEmailSubject?: string;
  subscriptionCreateSignupEmailBody?: string;
  subscriptionCreateSignupEmailBccAddress?: string;

  sendSubscriptionCreateEmail?: boolean;
  sendSubscriptionCreateEmailBcc?: boolean;
  subscriptionCreateEmailSubject?: string;
  subscriptionCreateEmailBody?: string;
  subscriptionCreateEmailBccAddress?: string;

  sendCancelEmail?: boolean;
  sendCancelEmailBcc?: boolean;
  cancelEmailSubject?: string;
  cancelEmailBody?: string;
  cancelEmailBccAddress?: string;

  sendUpcomingOrderEmail?: boolean;
  sendUpcomingOrderEmailBcc?: boolean;
  upcomingOrderEmailSubject?: string;
  upcomingOrderEmailBody?: string;
  upcomingOrderEmailBccAddress?: string;

  upcomingOrderEmailDeliveryDelay?: number;

  sendUpdatePaymentEmailOnFailedPayment?: boolean;

  sendUpcomingGiftEmail?: boolean;
  sendUpcomingGiftEmailBcc?: boolean;
  upcomingGiftEmailSubject?: string;
  upcomingGiftEmailBody?: string;
  upcomingGiftEmailBccAddress?: string;

  sendExpiredPaymentMethodEmail?: boolean;
  sendExpiredPaymentMethodEmailBcc?: boolean;
  expiredPaymentMethodEmailSubject?: string;
  expiredPaymentMethodEmailBody?: string;
  expiredPaymentMethodEmailBccAddress?: string;

  sendGiftIsBeingSentEmail?: boolean;
  sendGiftIsBeingSentEmailBcc?: boolean;
  giftIsBeingSentEmailSubject?: string;
  giftIsBeingSentEmailBody?: string;
  giftIsBeingSentEmailBccAddress?: string;

  sendGifterUpcomingGiftEmail?: boolean;
  gifterUpcomingGiftEmailSubject?: string;
  gifterUpcomingGiftEmailBody?: string;
  gifterUpcomingGiftEmailBccAddress?: string;
  sendGifterUpcomingGiftEmailBcc?: boolean;

  itemOutOfStockEmail?: boolean;
  itemOutOfStockEmailBcc?: boolean;
  itemOutOfStockEmailSubject?: string;
  itemOutOfStockEmailBody?: string;
  itemOutOfStockEmailBccAddress?: string;

  sendSubscriptionPausedEmail?: boolean;
  sendSubscriptionPausedEmailBcc?: boolean;
  subscriptionPausedEmailSubject?: string;
  subscriptionPausedEmailBody?: string;
  subscriptionPausedEmailBccAddress?: string;

  sendSubscriptionAutoResumeEmail?: boolean;
  sendSubscriptionAutoResumeEmailBcc?: boolean;
  subscriptionAutoResumeEmailSubject?: string;
  subscriptionAutoResumeEmailBody?: string;
  subscriptionAutoResumeEmailBccAddress?: string;

  sendSubscriptionResumedEmail?: boolean;
  sendSubscriptionResumedEmailBcc?: boolean;
  subscriptionResumedSubject?: string;
  subscriptionResumedEmailBody?: string;
  subscriptionResumedEmailBccAddress?: string;

  emailHeader?: string;
  emailFooter?: string;
  emailTheming?: ICustomerEmailTheming;

  isArpuEnabled?: boolean;

  oneTimeCodeEmailSubject?: string;
  oneTimeCodeEmailBody?: string;
  magicLinkEmailSubject?: string;
  magicLinkEmailBody?: string;

  sendSuccessfulReferralEmail?: boolean;
  sendSuccessfulReferralEmailBcc?: boolean;
  successfulReferralEmailSubject?: string;
  successfulReferralEmailBody?: string;
  successfulReferralEmailBccAddress?: string;

  sendPaymentFailureEmail?: boolean;
  sendPaymentFailureEmailBcc?: boolean;
  paymentFailureEmailSubject?: string;
  paymentFailureEmailBody?: string;
  paymentFailureEmailBccAddress?: string;

  sendAccountCreationReminderEmail?: boolean;
  sendAccountCreationReminderEmailBcc?: boolean;
  accountCreationReminderEmailSubject?: string;
  accountCreationReminderEmailBody?: string;
  accountCreationReminderEmailBccAddress?: string;

  sendBonusEarnedEmail?: boolean;
  bonusEarnedEmailSubject?: string;
  bonusEarnedEmailBody?: string;
  sendBonusEarnedEmailBcc?: boolean;
  bonusEarnedEmailBccAddress?: string;

  sendOtpCodeRedeemedEmail?: boolean;
  otpCodeRedeemedEmailSubject?: string;
  otpCodeRedeemedEmailBody?: string;
  sendOtpCodeRedeemedEmailBcc?: boolean;
  otpCodeRedeemedEmailBccAddress?: string;

  sendRewardReminderEmail?: boolean;
  rewardReminderEmailSubject?: string;
  rewardReminderEmailBody?: string;
  sendRewardReminderEmailBcc?: boolean;
  rewardReminderEmailBccAddress?: string;

  sendRewardOutOfStockEmail?: boolean;
  rewardOutOfStockEmailSubject?: string;
  rewardOutOfStockEmailBody?: string;
  sendRewardOutOfStockEmailBcc?: boolean;
  rewardOutOfStockEmailBccAddress?: string;

  sendLoyaltyCurrencyReminder?: boolean;
  loyaltyCurrencyReminderEmailSubject?: string;
  loyaltyCurrencyReminderEmailBody?: string;

  sendMigrationSummaryEmail?: boolean;
  migrationSummaryEmailSubject?: string;
  migrationSummaryEmailBody?: string;

  sendInsightsEmail?: boolean;

  sendLoyaltyWelcomeEmail?: boolean;
  loyaltyWelcomeEmailSubject?: string;
  loyaltyWelcomeEmailBody?: string;
  sendLoyaltyWelcomeEmailBcc?: boolean;
  loyaltyWelcomeEmailBccAddress?: string;

  sendLoyaltyTiersWelcomeEmail?: boolean;
  loyaltyTiersWelcomeEmailSubject?: string;
  loyaltyTiersWelcomeEmailBody?: string;
  sendLoyaltyTiersWelcomeEmailBcc?: boolean;
  loyaltyTiersWelcomeBccAddress?: string;

  sendLoyaltyTierAdvancementEmail?: boolean;
  loyaltyTierAdvancementEmailSubject?: string;
  loyaltyTierAdvancementEmailBody?: string;
  sendLoyaltyTierAdvancementEmailBcc?: boolean;
  loyaltyTierAdvancementBccAddress?: string;
}
