import { EmailSectionsEnum } from "@smartrr/shared/entities/CustomerEmailConfig";
import { ReactElement } from "react";
import { Variable } from "./components/TableVariable";
import styled from "styled-components";
import { Box } from "@mui/material";
import { IEmailPreview } from "@smartrr/shared/entities/CustomerEmailConfig/types";

/**  Disabling es-lint as it will require a `key` prop for each array which is unnecessary in this case*/
/* eslint-disable */
export const SubscriptionVariables = [
  [<Variable parent="subscription">id</Variable>, "Shopify subscription ID"],
  [<Variable parent="subscription">nextBillingDate</Variable>, "Next billing date, formatted as ISO 8601 string"],
  [<Variable parent="subscription">currency</Variable>, "Subscription currency"],
  [
    <Variable parent="subscription">totalEstimatedNet</Variable>,
    "Total estimated price of subscription, formatted per currency",
  ],
  [
    <Variable parent="subscription">totalDiscount</Variable>,
    "Total amount of discounts applied to the subscription, formatted per currency",
  ],
  [
    <Variable parent="subscription">totalFromLineItems</Variable>,
    "Total from subscription line items, formatted per currency",
  ],
  [
    <Variable parent="subscription">totalLineItemsDiscount</Variable>,
    "Total discount applied to line items, formatted per currency",
  ],
  [
    <Variable parent="subscription">totalShipping</Variable>,
    "Total shipping price of subscription, formatted per currency",
  ],
  [
    <Variable parent="subscription">totalShippingDiscount</Variable>,
    "Total discount applied to shipping price, formatted per currency",
  ],
  [
    <Variable parent="subscription">cardType</Variable>,
    "Brand of card from which the subscription payment was made (i.e. Visa, Mastercard, etc)",
  ],
  [<Variable parent="subscription">deliveryDate</Variable>, "Estimated order delivery date"],
  [
    <Variable parent="subscription">unpauseDate</Variable>,
    "The Date in which a paused subscription will auto-resume on",
  ],
  [
    <Variable parent="subscription">daysTillUnpause</Variable>,
    "Number of days until a paused subscription is auto-resumed",
  ],
  [
    <Variable parent="subscription">daysTillDelivery</Variable>,
    "Number of estimated days until a subscription is delivered",
  ],
  [<Variable parent="subscription">orderNote</Variable>, "Order note left by customer"],
];

export const LineVariables = [
  [<Variable parent="subscription.lines[0]">id</Variable>, "Shopify subscription ID"],
  [<Variable parent="subscription.lines[0]">basePrice</Variable>, "Line base price, formatted per line currency"],
  [<Variable parent="subscription.lines[0]">isAddOn</Variable>, "Whether line is an addon"],
  [
    <Variable parent="subscription.lines[0]">priceAfterDiscounts</Variable>,
    "Subscription price after discounts are applied, formatted per line currency",
  ],
  [<Variable parent="subscription.lines[0]">quantity</Variable>, "Line quantity"],
  [<Variable parent="subscription.lines[0]">product.id</Variable>, "Shopify product ID"],
  [<Variable parent="subscription.lines[0]">product.name</Variable>, "Product name"],
  [<Variable parent="subscription.lines[0]">variant.id</Variable>, "Shopify variant ID"],
  [<Variable parent="subscription.lines[0]">variant.name</Variable>, "Variant's name"],
  [<Variable parent="subscription.lines[0]">variant.image</Variable>, "Variant's image"],
];

export const CustomerVariables = [
  [<Variable parent="customer">id</Variable>, "Shopify subscription ID"],
  [<Variable parent="customer">firstName</Variable>, "Customer first name"],
  [<Variable parent="customer">lastName</Variable>, "Customer last name"],
  [<Variable parent="customer">email</Variable>, "Customer email"],
  [<Variable parent="customer">phone</Variable>, "Customer phone number"],
];

export const NoteVariables = [[<Variable>note</Variable>, "Gift note"]];

export const ToVariables = [
  [<Variable parent="to">address1</Variable>, "Street address line 1"],
  [<Variable parent="to">address2</Variable>, "Street address line 2"],
  [<Variable parent="to">city</Variable>, "Address city"],
  [<Variable parent="to">company</Variable>, "Company name"],
  [<Variable parent="to">country</Variable>, "Country name"],
  [<Variable parent="to">countryCode</Variable>, "Country code"],
  [<Variable parent="to">firstName</Variable>, "Giftee's first name"],
  [<Variable parent="to">lastName</Variable>, "Giftee's last name"],
  [<Variable parent="to">phone</Variable>, "Phone number"],
  [<Variable parent="to">province</Variable>, "Province / state"],
  [<Variable parent="to">provinceCode</Variable>, "Province / state code"],
  [<Variable parent="to">zip</Variable>, "Zip code"],
];

export const FromVariables = [
  [<Variable parent="from">firstName</Variable>, "Sender's first name"],
  [<Variable parent="from">lastName</Variable>, "Sender's last name"],
];

export const ItemOOSVariables = [
  [<Variable parent="outOfStockItem">id</Variable>, "Shopify product ID"],
  [<Variable parent="outOfStockItem">name</Variable>, "Shopify product name"],
  [<Variable parent="outOfStockItem">variantName</Variable>, "Shopify product variant name"],
  [
    <Variable parent="outOfStockItem">unavailableReason</Variable>,
    "Reason for item being marked as out of stock",
  ],
  [<Variable parent="outOfStockItem">image</Variable>, "Variant's image"],
];

export const PasswordlessVariables = [
  [<Variable parent="passwordless">oneTimeCode</Variable>, "6 digit code users will use to login"],
  [<Variable parent="passwordless">expirationLength</Variable>, "Time before code expirers"],
  [<Variable parent="passwordless">loginUrl</Variable>, "A plain text link of magic link url"],
];

export const ReferralProgramVariables = [
  [<Variable parent="referrals">referrer.firstName</Variable>, "First name of referrer"],
  [<Variable parent="referrals">referrer.lastName</Variable>, "Last name of referrer"],
  [<Variable parent="referrals">referrer.email</Variable>, "Email of referrer"],
  [<Variable parent="referrals">referrer.phone</Variable>, "Phone number of referrer"],
  [<Variable parent="referrals">pointsEarned</Variable>, "Points earned by referrer"],
  [<Variable parent="referrals">pointBalance</Variable>, "Current point balance of referrer"],
  [<Variable parent="referrals">referee.firstName</Variable>, "First name of user redeeming code"],
  [<Variable parent="referrals">referee.lastName</Variable>, "Last name of user redeeming code"],
  [<Variable parent="referrals">referee.email</Variable>, "Email of user redeeming code"],
  [<Variable parent="referrals">referee.phone</Variable>, "Phone number of user redeeming code"],
];

export const AccountCreationReminderVariables = [
  [
    <Variable parent="accountCreationReminder">accountCreationReminder.pointsEarned</Variable>,
    "Points earned for account creation",
  ],
  [<Variable parent="customer">customer.id</Variable>, "Shopify subscription ID"],
  [<Variable parent="customer">customer.firstName</Variable>, "Customer first name"],
  [<Variable parent="customer">customer.lastName</Variable>, "Customer last name"],
  [<Variable parent="customer">customer.email</Variable>, "Customer email"],
  [<Variable parent="customer">customer.phone</Variable>, "Customer phone number"],
];

export const BonusEarnReminderVariables = [
  [<Variable parent="bonusEarnedReminder">pointsEarned</Variable>, "Points earned by customer"],
  [
    <Variable parent="bonusEarnedReminder">bonusPointsOrderNumber</Variable>,
    "On which order customer receives the reward",
  ],
  [<Variable parent="bonusEarnedReminder">customerBalance</Variable>, "Customer's remaining point balance"],
  [<Variable parent="bonusEarnedReminder">currentMonth</Variable>, "The month in which the email is sent"],
  [
    <Variable parent="bonusEarnedReminder">loyaltyItems</Variable>,
    "Array of at most 8 loyalty items that can be shown",
  ],
  [<Variable parent="customer">customer.id</Variable>, "Shopify subscription ID"],
  [<Variable parent="customer">customer.firstName</Variable>, "Customer first name"],
  [<Variable parent="customer">customer.lastName</Variable>, "Customer last name"],
  [<Variable parent="customer">customer.email</Variable>, "Customer email"],
  [<Variable parent="customer">customer.phone</Variable>, "Customer phone number"],
];

export const OtpCodeRedemptionVariables = [
  [<Variable parent="otpRedemption">discountCode</Variable>, "Discount code received by customer"],
  [<Variable parent="otpRedemption">itemRedeemed</Variable>, "Loyalty item received by customer"],
  [<Variable parent="customer">customer.id</Variable>, "Shopify subscription ID"],
  [<Variable parent="customer">customer.firstName</Variable>, "Customer first name"],
  [<Variable parent="customer">customer.lastName</Variable>, "Customer last name"],
  [<Variable parent="customer">customer.email</Variable>, "Customer email"],
  [<Variable parent="customer">customer.phone</Variable>, "Customer phone number"],
];

export const RewardsReminderVariables = [
  [<Variable parent="rewardsReminder">customerBalance</Variable>, "Customer's remaining point balance"],
  [<Variable parent="rewardsReminder">currentMonth</Variable>, "The month in which the email is sent"],
  [
    <Variable parent="rewardsReminder">loyaltyItems</Variable>,
    "Array of at most 8 loyalty items that can be shown",
  ],
  [<Variable parent="customer">customer.id</Variable>, "Shopify subscription ID"],
  [<Variable parent="customer">customer.firstName</Variable>, "Customer first name"],
  [<Variable parent="customer">customer.lastName</Variable>, "Customer last name"],
  [<Variable parent="customer">customer.email</Variable>, "Customer email"],
  [<Variable parent="customer">customer.phone</Variable>, "Customer phone number"],
];

/* eslint-enable */

export const toggleSwitchButtons = [
  {
    label: "ON",
    disabled: false,
    value: true,
  },
  {
    label: "OFF",
    disabled: false,
    value: false,
  },
];

// TODO: Move somewhere else or reorganize file

export interface EmailsWithMappedSections {
  section: EmailCardSection;
  emails: { [x: string]: IEmailPreview }[];
}

export interface EmailCardSection {
  title: string;
  section: EmailSectionsEnum;
  description?: ReactElement;
}

export const EmailPreviewWrapper = styled(Box)<{ modal?: boolean }>`
  ${props =>
    !props.modal &&
    `
    border-top: var(--p-border-width-1) solid var(--p-color-border-subdued);
    margin: 20px 0;
    padding: 20px 0;
`}

  // Resetting outsides styles affecting emails
  .box {
    background-color: transparent;
    border-radius: 0px;
    box-shadow: none;
    color: inherit;
    margin-bottom: 0;
    padding: 0;
  }

  // Ensuring email fills card completely
  table.mw-100p,
  table.templateContainer {
    width: inherit !important;

    .pb-5 {
      padding-bottom: 5px !important;
    }
  }
`;
