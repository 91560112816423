import * as yup from "yup";

import { ISharedEntityFields } from "./shared/SharedEntityFields";
import { RequireNilFields } from "../utils/RequireNilFields";

export interface IPhoneDB extends IPhone, ISharedEntityFields {}

export interface IPhone {
  phoneNumber: string;
  twilioAccountSid: string;
  sendUpcomingOrderSms?: boolean;
}

export type IPhoneConfigurable = Pick<IPhone, "sendUpcomingOrderSms">;

export type IPhoneForm = RequireNilFields<IPhoneConfigurable>;

export const customerPhoneValidationSchema = yup.object().shape<IPhoneForm>({
  sendUpcomingOrderSms: yup.bool().required(),
});
