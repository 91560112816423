import { ICustomerEmailConfigForm, IEmailPreview } from "@smartrr/shared/entities/CustomerEmailConfig/types";
import React from "react";

import {
  TypedDropdownProps,
  TypedInputProps,
  TypedLabelProps,
  TypedRadioProps,
  TypedTextAreaProps,
  ValidationErrorProps,
} from "@vendor-app/app/_sharedComponents/TypedForm/useTypedForm";

import { toggleSwitchButtons } from "../constants";

interface Props {
  useField: <V extends keyof ICustomerEmailConfigForm>(
    field: V
  ) => {
    Input: React.MemoExoticComponent<(inputProps: TypedInputProps<ICustomerEmailConfigForm, V>) => JSX.Element>;
    Label: React.MemoExoticComponent<(labelProps: TypedLabelProps<ICustomerEmailConfigForm, V>) => JSX.Element>;
    ValidationError: React.MemoExoticComponent<
      (validationProps: ValidationErrorProps<ICustomerEmailConfigForm, V>) => JSX.Element
    >;
    Dropdown: React.MemoExoticComponent<
      (validationProps: TypedDropdownProps<ICustomerEmailConfigForm, V>) => JSX.Element
    >;
    Radio: React.MemoExoticComponent<
      (validationProps: TypedRadioProps<ICustomerEmailConfigForm, V>) => JSX.Element
    >;
    TextArea: React.MemoExoticComponent<
      (validationProps: TypedTextAreaProps<ICustomerEmailConfigForm>) => JSX.Element
    >;
  };
  manipulatedEmailConfigData: IEmailPreview;
  defaultsEnabled?: boolean;
}

export const SendEmailToggle = ({ useField, manipulatedEmailConfigData }: Props) => {
  const { Radio: Toggle } = useField(manipulatedEmailConfigData.configValues?.toggle!);
  return (
    <Toggle className="sendEmailToggle" name="radio" align="left" label="" buttons={toggleSwitchButtons} useMui />
  );
};

export const BccAddressInput = ({ useField, manipulatedEmailConfigData }: Props) => {
  const { TextArea: BccAddressInput } = useField(manipulatedEmailConfigData.configValues?.bccAddress!);
  return <BccAddressInput label="" usePolaris />;
};

export const SendBccToggle = ({ useField, manipulatedEmailConfigData }: Props) => {
  const { Input: SendBccToggle } = useField(manipulatedEmailConfigData.configValues?.sendBccAddress!);
  return <SendBccToggle label="BCC" usePolaris type="checkbox" />;
};

export const SubjectInput = ({ useField, manipulatedEmailConfigData, defaultsEnabled }: Props) => {
  const { Input: SubjectInput } = useField(manipulatedEmailConfigData.configValues?.subject!);
  return <SubjectInput type="text" label="Subject Line" disabled={defaultsEnabled} usePolaris />;
};
