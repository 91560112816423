import { copyToClipboard } from "@smartrr/shared/utils/copyToClipboard";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import React, { useCallback } from "react";

import { CodeSnippet } from "../styles";
interface ITableVariableProps {
  children: string;
  parent?:
    | "subscription"
    | "subscription.lines[0]"
    | "customer"
    | "to"
    | "from"
    | "outOfStockItem"
    | "passwordless"
    | "referrals"
    | "accountCreationReminder"
    | "bonusEarnedReminder"
    | "otpRedemption"
    | "rewardsReminder";
}

export function Variable({ children, parent }: ITableVariableProps) {
  const { addToast } = useToast();
  const copyVariableToClipboard = useCallback(() => {
    const formattedVariable = parent ? `${parent}.${children}` : children;
    const templatedVariable = `<%- ${formattedVariable} %>`;

    copyToClipboard(templatedVariable, "Variable copied to clipboard", addToast);
  }, []);
  return <CodeSnippet onClick={copyVariableToClipboard}>{children}</CodeSnippet>;
}
