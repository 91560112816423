import config from "@vendor-app/config";

export const openTwilioConnectWindow = async (orgId: string) =>
  new Promise<"success">((resolve, reject) => {
    const oauthWindow = window.open(
      `${config.twilio.authorizeUrl}?state=${orgId}`,
      "_blank",
      "toolbar=no, menubar=no"
    );
    if (oauthWindow) {
      const messageEventListener = (event: MessageEvent<"success">) => {
        if (["success", "failure"].includes(event.data)) {
          if (event.data === "success") {
            resolve(event.data);
          } else if (event.data === "failure") {
            reject(event.data);
          }
          oauthWindow.close();
          window.removeEventListener("message", messageEventListener);
        }
      };
      window.addEventListener("message", messageEventListener);
    }
  });
