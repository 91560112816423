import { Banner, Layout, Page } from "@shopify/polaris";
import { MobileMajor } from "@shopify/polaris-icons";
import { Box } from "@smartrr/shared/components/primitives";
import React from "react";
import styled from "styled-components";

import { SmsForm } from "./components/SmsForm";

const SmsSettingsBox = styled(Box)`
  & > .Polaris-Text--headingMd {
    color: #202223;
    font-weight: 590;
  }
  & .Polaris-Icon--colorInkLighter::before {
    background-color: transparent !important;
  }

  & > .subheading {
    color: #6f7c88;
  }
`;

export function SmsSettings(): JSX.Element {
  const [showBanner, setShowBanner] = React.useState(
    JSON.parse(localStorage.getItem("sms-is-open") || "{}") || false
  );
  const dismissBanner = () => {
    localStorage.setItem("sms-is-open", JSON.stringify(!showBanner));
    setShowBanner(!showBanner);
  };

  return (
    <Page title="">
      <Layout>
        <Layout.Section>
          <SmsSettingsBox vertical gap={2}>
            {!!showBanner && (
              <Banner
                icon={MobileMajor}
                onDismiss={() => {
                  dismissBanner();
                }}
              >
                <p>
                  SMS notifications can be used to let your customers know about their upcoming orders. To read
                  more about setting up SMS notifications, see our{" "}
                  <a
                    style={{ textDecoration: "underline" }}
                    rel="noreferrer"
                    href="https://help.smartrr.com/docs/support/subscription-setup/how-do-i-set-up-sms-subscription-notifications"
                    target={"_blank"}
                  >
                    help doc.
                  </a>
                </p>
                <br />
                <p>
                  If you don&apos;t already have a Twilio account, you use{" "}
                  <a
                    href="https://www.twilio.com/try-twilio?g=%2Fconsole%2Fusage%2Fauthorized-app%3F&t=0e9c790704cbdfcb3530ebc9b12980d14ecf9d977902542eb5effa4787c49148"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "underline" }}
                  >
                    {" "}
                    this link
                  </a>{" "}
                  to get started.
                </p>
                <br />
                <p>
                  There&apos;s also the option to integrate Smartrr with other SMS partners. You can view these
                  options in our <span style={{ fontWeight: "bold" }}>Integrations</span> tab.
                </p>
              </Banner>
            )}
            <SmsForm />
          </SmsSettingsBox>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
