import { Box } from "@mui/material";
import {
  Banner,
  Button,
  ContextualSaveBar,
  FormLayout,
  Layout,
  LegacyCard,
  LegacyStack,
  Link,
  Modal,
  Page,
  Text,
} from "@shopify/polaris";
import { NO_OP_CALLBACK } from "@smartrr/shared/constants";
import {
  NotificationEmails as NotificationEmailsEnum,
  EmailSectionsEnum,
} from "@smartrr/shared/entities/CustomerEmailConfig";
import { RequireNilFields } from "@smartrr/shared/utils/RequireNilFields";
import { isNil, pickBy } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import * as yup from "yup";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useTypedForm } from "@vendor-app/app/_sharedComponents/TypedForm/useTypedForm";
import { resetScroll } from "@vendor-app/utils/resetAdminScroll";

import {
  BccAddressInput,
  SendBccToggle,
  SendEmailToggle,
  SubjectInput,
} from "./libs/components/ConditionalFields";
import { VariablesTable } from "./libs/components/VariablesTable";
import { EditEmailsPageWrapper, NotificationBox } from "./libs/styles";
import { simulateClickOnEle } from "@smartrr/shared/utils/simulateActions";
import { InputWrapper } from "../../../AdminCreateSubscriptionRoute/libs/components/ImportCustomerModal/styles";
import {
  CustomerNotificationViews,
  ICustomerEmailConfig,
  ICustomerEmailConfigForm,
  ICustomerEmailPageProps,
} from "@smartrr/shared/entities/CustomerEmailConfig/types";
import useGenerateEmailConfigOptions from "./libs/hooks/useGenerateEmailConfigOptions";
import { useEmailPreviewModalStore } from "@vendor-app/app/_sharedComponents/NotificationEmails/EmailPreviewModal/lib/useEmailPreviewModalStore";
import { EmailNotificationPreviewModal } from "@vendor-app/app/_sharedComponents/NotificationEmails/EmailPreviewModal";
import { useDefaultEmailConfig } from "./libs/hooks";
import { EmailNotificationAccessStore } from "@vendor-app/app/_state/zustand/EmailNotificationsStore";
import { NotificationEmails } from "@smartrr/shared/entities/CustomerEmailConfig/schemas";

interface EditEmailProps extends ICustomerEmailPageProps {
  navigationView: {
    view: CustomerNotificationViews;
    emailType?: NotificationEmailsEnum | undefined;
  };
}

export const EditEmail = (props: EditEmailProps) => {
  const { emailConfigs } = EmailNotificationAccessStore.useEmailConfigs();

  if (!emailConfigs) {
    return <React.Fragment />;
  }

  return <EditEmailInner {...props} customerEmailConfig={emailConfigs} />;
};

const EditEmailInner = ({
  areValidChanges,
  navigationView,
  setView,
  customerEmailConfig,
}: EditEmailProps & {
  customerEmailConfig: NotificationEmails.Type;
}) => {
  const { update: updateEmailNotification } = EmailNotificationAccessStore.useActions();

  const onEmailConfigSave = useCallback(async (formValues: NotificationEmails.Type) => {
    updateEmailNotification(formValues);
    addToast("Email notifications updated");
  }, []);
  const defaultEmailConfig = useDefaultEmailConfig();
  const { addToast } = useToast();
  const { view, emailType } = navigationView;
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  const openModal = useEmailPreviewModalStore(state => state.openModal);

  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => resetScroll(), []);

  if (!emailType) {
    setView({ view: CustomerNotificationViews.DEFAULT, emailType: undefined });
  }

  const customerEmailConfigFormattedRecord = useGenerateEmailConfigOptions({
    defaults: false,
    allowShopifyEmails: false,
  });

  const manipulatedEmailConfigData = customerEmailConfigFormattedRecord[emailType!];
  const defaultsEnabled = customerEmailConfig.emailTheming?.useStyling;
  const isPasswordlessEmail = manipulatedEmailConfigData.section === EmailSectionsEnum.PASSWORDLESS_LOGIN;
  const isUpcomingOrderEmail = emailType === NotificationEmailsEnum.SUBSCRIPTION_UPCOMING_ORDER;

  const { useField, useValues, setFieldValue } = useTypedForm<ICustomerEmailConfigForm>({
    initialValues: {
      ...defaultEmailConfig,
      ...pickBy(customerEmailConfig, value => !isNil(value)),
    },
    validationSchema: yup.object(), // TODO: Deprecate yup validation in favor of zod
    validateOnChange: true,
    onSubmit: NO_OP_CALLBACK,
  });

  const formValues = useValues();

  const hasChanges = EmailNotificationAccessStore.useHasChanges(formValues);

  const { TextArea: BodyTextArea } = useField(manipulatedEmailConfigData.configValues?.body!);

  const { Input: UpcomingOrderEmailDeliveryDelayInput } = useField("upcomingOrderEmailDeliveryDelay");

  const sendEmailToggleVal = formValues[manipulatedEmailConfigData.configValues?.toggle!] ?? false;
  const subjectVal = formValues[manipulatedEmailConfigData.configValues?.subject!];
  const bodyVal = formValues[manipulatedEmailConfigData.configValues?.body!];
  const sendBccToggleVal = formValues[manipulatedEmailConfigData.configValues?.sendBccAddress!];

  const upcomingOrderEmailDeliveryDelayVal = customerEmailConfig.upcomingOrderEmailDeliveryDelay;

  const updateModalValue = useCallback(() => {
    openModal({
      defaults: false,
      formValues,
      ableToSendPreview: true,
      emailPreview: {
        ...manipulatedEmailConfigData,
        emailBody: (bodyVal as string) ?? "",
        emailSubject: (subjectVal as string) ?? "",
      },
    });
  }, [formValues, bodyVal, subjectVal]);

  const onResetInputToDefault = useCallback(() => {
    setFieldValue(
      manipulatedEmailConfigData.configValues?.body!,
      defaultEmailConfig[manipulatedEmailConfigData.configValues?.body!]
    );
    setFieldValue("upcomingOrderEmailDeliveryDelay", defaultEmailConfig.upcomingOrderEmailDeliveryDelay);
    setFieldValue(
      manipulatedEmailConfigData.configValues?.subject!,
      defaultEmailConfig[manipulatedEmailConfigData.configValues?.subject!]
    );
  }, [manipulatedEmailConfigData, defaultEmailConfig, formValues]);

  const onNavigateToTemplates = useCallback(
    (formValues: RequireNilFields<ICustomerEmailConfig>, exitNoSave: boolean) => {
      if (!areValidChanges(formValues, manipulatedEmailConfigData)) {
        return;
      }

      if (hasChanges && !exitNoSave) {
        setShowUnsavedChangesModal(true);
        return;
      }

      setView({ view: CustomerNotificationViews.EDIT_TEMPLATES, emailType: undefined });
    },
    [manipulatedEmailConfigData, hasChanges]
  );

  const paginationHandler = useCallback(
    (direction: number) => {
      if (!areValidChanges(formValues, manipulatedEmailConfigData)) {
        return;
      }

      const bccAddressVal = formValues[manipulatedEmailConfigData.configValues?.bccAddress!];
      const sendBccToggleVal = formValues[manipulatedEmailConfigData.configValues?.sendBccAddress!];

      if (!sendBccToggleVal && bccAddressVal) {
        // If user closed BCC toggle but field is still populated, clear field
        setFieldValue(manipulatedEmailConfigData.configValues?.bccAddress!, "");
      }

      const valArr = Object.entries(customerEmailConfigFormattedRecord);

      const currIndex = valArr.findIndex(e => e[1].title === manipulatedEmailConfigData.title);
      const totalEntries = valArr.length;

      const newIndex = (currIndex + direction + totalEntries) % totalEntries;

      setView({
        view,
        emailType: valArr[newIndex][0] as NotificationEmailsEnum,
      });
    },
    [customerEmailConfigFormattedRecord, manipulatedEmailConfigData, setView, formValues]
  );

  const handleReset = useCallback(() => {
    for (const obj of Object.entries(manipulatedEmailConfigData.configValues!)) {
      const value = obj[1];
      setFieldValue(value, customerEmailConfig[value]);
    }
    // visually resetting toggle element
    if (
      formValues[manipulatedEmailConfigData.configValues?.toggle! as keyof ICustomerEmailConfig] !==
      customerEmailConfig[manipulatedEmailConfigData.configValues?.toggle! as keyof ICustomerEmailConfig]
    ) {
      simulateClickOnEle(boxRef, ".MuiSwitch-input");
    }

    if (upcomingOrderEmailDeliveryDelayVal !== formValues.upcomingOrderEmailDeliveryDelay) {
      setFieldValue("upcomingOrderEmailDeliveryDelay", customerEmailConfig.upcomingOrderEmailDeliveryDelay);
    }
  }, [manipulatedEmailConfigData, upcomingOrderEmailDeliveryDelayVal, formValues, setFieldValue]);

  useEffect(() => {
    if (
      emailType === NotificationEmailsEnum.SUBSCRIPTION_UPCOMING_ORDER &&
      !!sendEmailToggleVal &&
      formValues.isArpuEnabled
    ) {
      simulateClickOnEle(boxRef, ".MuiSwitch-input");

      addToast("Disable ARPU before enabling this email", true);
    }
  }, [sendEmailToggleVal]);

  return (
    <EditEmailsPageWrapper ref={boxRef}>
      {hasChanges ? (
        <ContextualSaveBar
          message="Unsaved changes"
          saveAction={{
            onAction: () =>
              areValidChanges(formValues, manipulatedEmailConfigData) && onEmailConfigSave(formValues),
            content: "Save changes",
            disabled: false,
          }}
          discardAction={{
            content: "Discard changes",
            onAction: handleReset,
          }}
        />
      ) : null}
      <Page
        backAction={{
          content: "Products",
          onAction: () => setView(prev => ({ ...prev, view: CustomerNotificationViews.DEFAULT })),
        }}
        title={manipulatedEmailConfigData.title}
        {...(!manipulatedEmailConfigData.hideToggle && {
          titleMetadata: (
            <SendEmailToggle manipulatedEmailConfigData={manipulatedEmailConfigData} useField={useField} />
          ),
        })}
        primaryAction={{
          content: "Preview",
          onAction: updateModalValue,
        }}
        pagination={{
          hasPrevious: true,
          hasNext: true,
          onNext: () => paginationHandler(1),
          onPrevious: () => paginationHandler(-1),
        }}
        compactTitle
        fullWidth
        divider
      >
        {defaultsEnabled ? (
          <Banner title="Default email templates are enabled">
            <Text as="span" variant="bodyMd">
              To edit below, you&apos;ll need to disable default email templates in{" "}
              <Link onClick={() => onNavigateToTemplates(formValues, false)} removeUnderline>
                Customize default template settings
              </Link>
              .
            </Text>
          </Banner>
        ) : null}
        <Layout>
          <Layout.Section>
            <LegacyCard sectioned>
              <FormLayout>
                <FormLayout.Group condensed>
                  <LegacyStack vertical spacing="loose">
                    <NotificationBox>
                      <FormLayout>
                        <Text as="p" variant="bodyMd" color="subdued">
                          {manipulatedEmailConfigData.description}
                        </Text>
                        {isUpcomingOrderEmail ? (
                          <React.Fragment>
                            <Text as="p" variant="bodyMd">
                              Email send time
                            </Text>
                            <p style={{ color: "var(--p-color-text-subdued)", margin: "3px 0" }}>
                              Days before subscription order is placed
                            </p>
                            <Box sx={{ maxWidth: "150px !important" }}>
                              <UpcomingOrderEmailDeliveryDelayInput
                                usePolaris
                                minLength={1}
                                type="number"
                                min={1}
                                max={60}
                                label=""
                                suffix="days"
                              />
                            </Box>
                          </React.Fragment>
                        ) : null}
                        <InputWrapper inlist={sendEmailToggleVal}>
                          <SubjectInput
                            useField={useField}
                            manipulatedEmailConfigData={manipulatedEmailConfigData}
                            defaultsEnabled={defaultsEnabled}
                          />
                        </InputWrapper>
                        {isPasswordlessEmail ? null : (
                          <div>
                            <SendBccToggle
                              useField={useField}
                              manipulatedEmailConfigData={manipulatedEmailConfigData}
                            />
                            {!!sendBccToggleVal && (
                              <BccAddressInput
                                useField={useField}
                                manipulatedEmailConfigData={manipulatedEmailConfigData}
                              />
                            )}
                          </div>
                        )}
                        <BodyTextArea label="Body" disabled={defaultsEnabled} usePolaris />
                      </FormLayout>
                      <Button size="slim" onClick={onResetInputToDefault}>
                        Reset to default
                      </Button>
                    </NotificationBox>
                  </LegacyStack>
                </FormLayout.Group>
              </FormLayout>
            </LegacyCard>
          </Layout.Section>
          <Layout.Section>
            <VariablesTable currentEmailType={emailType!} />
          </Layout.Section>
        </Layout>
      </Page>

      <EmailNotificationPreviewModal />

      <Modal
        title="Are you sure you want to exit?"
        open={showUnsavedChangesModal}
        onClose={() => setShowUnsavedChangesModal(false)}
        primaryAction={{
          content: "Save and exit",
          onAction() {
            if (areValidChanges(formValues, manipulatedEmailConfigData)) {
              onEmailConfigSave(formValues);
              onNavigateToTemplates(formValues, true);
            }
          },
        }}
        secondaryActions={[
          { content: "Exit without saving", onAction: () => onNavigateToTemplates(formValues, true) },
        ]}
      >
        <Text as="p" variant="bodyMd">
          Are you sure you want to exit?
        </Text>
      </Modal>
    </EditEmailsPageWrapper>
  );
};
