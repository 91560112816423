import { Stack } from "@mui/material";
import { Badge, Icon, Link, Text } from "@shopify/polaris";
import { ResetMinor } from "@shopify/polaris-icons";
import { IConfigRowOptionsProps, ICustomerEmailConfig } from "@smartrr/shared/entities/CustomerEmailConfig/types";
import { EmailSectionsEnum } from "@smartrr/shared/entities/CustomerEmailConfig";
import { isEmpty, kebabCase } from "lodash";
import React, { useState } from "react";

import {
  IntegrationBrands,
  IntegrationLogo,
} from "@vendor-app/app/AdminRoute/AdminIntegrationsRoute/integrations/integrationLogos";

import { ToggleSwitch } from "./ToggleSwitch";
import { StyledConfigRow } from "../styles";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { useApiKeys } from "../hooks";

export const EmailConfigRowOption = ({
  emailType,
  onClick,
  attributes,
  onEmailToggle,
  disabled,
}: IConfigRowOptionsProps): JSX.Element => {
  const { conditional, title, description, enabled, section, configValues, hideToggle } = attributes;
  const [toggled, setToggled] = useState(enabled ?? true);

  // Any emails sent from shopify need to be configured within Shopify Admin
  const isEmailUnavailable = section === EmailSectionsEnum.SENT_FROM_SHOPIFY;

  return (
    <StyledConfigRow
      data-emailtype={emailType}
      direction="row"
      display="flex"
      justifyContent="space-between"
      $toggled={toggled ?? true}
      className={`email-config-row`}
      data-testid={`email-config-row-${kebabCase(title.toLocaleLowerCase())}`}
    >
      <div className="email-config-row-title">
        {isEmailUnavailable ? (
          <Text as="p" variant="bodyMd" color="subdued">
            {title}
          </Text>
        ) : (
          <Link onClick={() => onClick(emailType!)}>{title}</Link>
        )}
      </div>
      <div className="email-config-row-description">
        <Text as="p" variant="bodyMd">
          {description}
        </Text>
        {conditional ? (
          <div className="email-config-row-conditional-badge">
            <Icon source={ResetMinor} color="subdued" />
            <Text as="span" variant="bodyMd" color="subdued">
              Conditional
            </Text>
          </div>
        ) : null}
      </div>

      <div className="email-config-row-switch">
        {!isEmailUnavailable && !hideToggle ? (
          <ToggleSwitch
            onEmailToggle={onEmailToggle}
            setToggled={setToggled}
            toggleConfigValue={configValues?.toggle!}
            toggled={toggled}
            disabled={disabled}
          />
        ) : null}
      </div>
    </StyledConfigRow>
  );
};

export const ArpuIntegrationConfigRow = ({
  onEmailToggle,
  enabled,
}: {
  onEmailToggle: (toggleValue: keyof ICustomerEmailConfig, checked: boolean) => void;
  enabled: boolean;
}) => {
  const { apiKeys } = useApiKeys();
  const isIntegrationConfigured = Boolean(!isEmpty(apiKeys) && apiKeys.arpu);
  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const [toggled, setToggled] = useState(isIntegrationConfigured ? enabled : false);

  const isArpuAvailable = activePlan?.planName === "Excel" || activePlan?.planName === "Grow";

  if (!isArpuAvailable) {
    return <React.Fragment />;
  }

  const configValue: keyof ICustomerEmailConfig = "isArpuEnabled";

  return (
    <StyledConfigRow
      direction="row"
      display="flex"
      justifyContent="space-between"
      $toggled={toggled ?? true}
      className="email-config-row-integration"
    >
      <div className="email-config-row-title"></div>
      <div className="email-config-row-description">
        <Stack direction="row" gap="10px" alignItems="center">
          <Badge>via integration</Badge> <IntegrationLogo brand={IntegrationBrands.Arpu} alt />
        </Stack>
        <Text as="span" variant="bodyMd" color="subdued">
          {isIntegrationConfigured ? "Account connected" : "No account connected"}
        </Text>
        <Text as="p" variant="bodyMd">
          Offers customers option to manage subscription without logging into their account. <br />
          <Text as="span" variant="bodyMd" color="subdued">
            *Manage this email from your <Link url="https://app.getarpu.com/login">ARPU admin</Link>.
          </Text>
        </Text>
      </div>

      <ToggleSwitch
        disabled={!isIntegrationConfigured}
        onEmailToggle={onEmailToggle}
        setToggled={setToggled}
        toggleConfigValue={configValue}
        toggled={toggled}
      />
    </StyledConfigRow>
  );
};
