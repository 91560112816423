import { Banner, ContextualSaveBar, FormLayout, LegacyCard, Page } from "@shopify/polaris";
import { EmailMajor } from "@shopify/polaris-icons";
import { NotificationEmails as NotificationEmailsEnum } from "@smartrr/shared/entities/CustomerEmailConfig";
import { simulateMouseClick, simulateClickOnEle } from "@smartrr/shared/utils/simulateActions";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { EmailSettingsLower, EmailSettingsUpper } from "./libs/styles";
import {
  CustomerNotificationViews,
  ICustomerEmailConfig,
  ICustomerEmailPageProps,
} from "@smartrr/shared/entities/CustomerEmailConfig/types";
import useGenerateEmailConfigOptions from "./libs/hooks/useGenerateEmailConfigOptions";
import { EmailSectionCards } from "./libs/components/EmailSectionCards";
import { EmailNotificationAccessStore } from "@vendor-app/app/_state/zustand/EmailNotificationsStore";
import { NotificationEmails } from "@smartrr/shared/entities/CustomerEmailConfig/schemas";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";

export const EmailNotifications = (props: ICustomerEmailPageProps): JSX.Element => {
  const { initialEmailConfigs } = EmailNotificationAccessStore.useEmailConfigs();
  if (!initialEmailConfigs) {
    return <React.Fragment />;
  }
  return <EmailNotificationsInner {...props} initialEmailConfigs={initialEmailConfigs} />;
};

const EmailNotificationsInner = ({
  setView,
  initialEmailConfigs,
}: ICustomerEmailPageProps & { initialEmailConfigs: NotificationEmails.Type }) => {
  const { addToast } = useToast();
  const { update: updateEmailNotification } = EmailNotificationAccessStore.useActions();

  const [showBanner, setShowBanner] = React.useState(
    JSON.parse(localStorage.getItem("email-is-open") || "{}") || false
  );
  const emailSettingsRef = useRef<HTMLDivElement>(null);

  const customerEmailConfigFormattedRecord = useGenerateEmailConfigOptions({ defaults: false });

  const [manipulatedValues, setManipulatedValues] = useState<NotificationEmails.Type>(initialEmailConfigs!);

  const hasChanges = EmailNotificationAccessStore.useHasChanges(manipulatedValues);

  const dismissBanner = () => {
    localStorage.setItem("email-is-open", JSON.stringify(!showBanner));
    setShowBanner(!showBanner);
  };

  const onRowClick = useCallback(
    (emailType: NotificationEmailsEnum) => setView({ view: CustomerNotificationViews.EDIT_EMAIL, emailType }),
    []
  );

  const onEmailConfigSave = useCallback(async (formValues: NotificationEmails.Type) => {
    updateEmailNotification(formValues);
    addToast("Email notifications updated");
  }, []);

  const onEmailToggle = useCallback(
    (toggleValue: keyof ICustomerEmailConfig, checked: boolean) => {
      // ensuring that arpu and upcoming order email are not enabled at the same time
      if (toggleValue === "isArpuEnabled" && checked && manipulatedValues.sendUpcomingOrderEmail) {
        simulateClickOnEle(emailSettingsRef, "[id='row-switch-sendUpcomingOrderEmail']");
      } else if (toggleValue === "sendUpcomingOrderEmail" && checked && manipulatedValues.isArpuEnabled) {
        simulateClickOnEle(emailSettingsRef, "[id='row-switch-isArpuEnabled']");
      }

      setManipulatedValues(prevState => ({
        ...prevState,
        [toggleValue]: checked,
      }));
    },
    [manipulatedValues]
  );

  const handleReset = useCallback(() => {
    // Updating values
    setManipulatedValues(initialEmailConfigs);

    // Updating the switch's value doesn't visually update the component
    // therefore we need to simulate a mouse click for those components that need it

    // targeting all switches within the page
    const eleArr: NodeListOf<
      Element & {
        checked: boolean; // checked is a property that's added for this switch component
      }
    > = emailSettingsRef.current?.querySelectorAll("[id*='row-switch']")!;
    if (eleArr?.length) {
      for (const ele of eleArr) {
        const configVal = ele.id.split("row-switch-")[1];
        if (ele.checked !== initialEmailConfigs?.[configVal as keyof ICustomerEmailConfig]) {
          simulateMouseClick(ele);
        }
      }
    }
  }, [initialEmailConfigs]);

  const emailConfigEntries = Object.entries(customerEmailConfigFormattedRecord);
  useEffect(() => {
    if (!manipulatedValues.sendUpcomingOrderEmail && !manipulatedValues.isArpuEnabled) {
      const conditionalEles = emailConfigEntries.filter(entry => entry[1].conditional);
      if (conditionalEles.length) {
        for (const ele of conditionalEles) {
          const [emailType, eleAttributes] = ele;
          if (manipulatedValues[eleAttributes.configValues?.toggle!]) {
            simulateClickOnEle(emailSettingsRef, `[data-emailtype='${emailType}'] input`);
          }
        }
      }
    }
  }, [manipulatedValues]);

  return (
    <Page>
      {hasChanges ? (
        // TODO: Save bar will be moved to dedicated component once email actions are moved to zustand store
        <ContextualSaveBar
          message="Unsaved changes"
          saveAction={{
            onAction: () => onEmailConfigSave(manipulatedValues),
            content: "Save changes",
            disabled: false,
          }}
          discardAction={{
            content: "Discard changes",
            onAction: handleReset,
          }}
        />
      ) : null}
      <EmailSettingsUpper flexDirection="column" display="flex" gap={"20px"}>
        {!!showBanner && (
          <Banner icon={EmailMajor} onDismiss={dismissBanner}>
            <p>
              Emails can be toggled on and off to fit the needs of your shop. HTML can be used to customize the
              wording and branding of your emails. You&apos;re able to send email notifications based on specific
              subscription events.
            </p>
          </Banner>
        )}
        <LegacyCard
          sectioned
          title="Customize default templates"
          footerActionAlignment="left"
          secondaryFooterActions={[
            {
              content: "Customize defaults",
              onAction: () => setView(prev => ({ ...prev, view: CustomerNotificationViews.EDIT_TEMPLATES })),
            },
          ]}
        >
          <FormLayout>
            <FormLayout.Group
              condensed
              helpText="Add a logo and change color schemes for default email templates."
            />
          </FormLayout>
        </LegacyCard>
      </EmailSettingsUpper>
      <EmailSettingsLower mt="20px" ref={emailSettingsRef}>
        <EmailSectionCards
          emailValues={manipulatedValues}
          rowActions={{
            onEmailToggle,
            onRowClick,
          }}
        />
      </EmailSettingsLower>
    </Page>
  );
};
