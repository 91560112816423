import { Switch } from "@mui/material";
import { Text } from "@shopify/polaris";
import { ICustomerEmailConfig } from "@smartrr/shared/entities/CustomerEmailConfig/types";
import React from "react";

export interface IToggleSwitchProps {
  setToggled: React.Dispatch<React.SetStateAction<boolean>>;
  toggled: boolean;
  onEmailToggle: (toggleValue: keyof ICustomerEmailConfig, checked: boolean) => void;
  toggleConfigValue: keyof ICustomerEmailConfig;
  disabled?: boolean;
}

export const ToggleSwitch = ({
  toggleConfigValue,
  setToggled,
  onEmailToggle,
  toggled,
  disabled = false,
}: IToggleSwitchProps) => {
  return (
    <div className="email-config-row-switch">
      <React.Fragment>
        <Switch
          id={`row-switch-${toggleConfigValue}`}
          value={toggled}
          onChange={e => {
            setToggled(e.target.checked);
            onEmailToggle(toggleConfigValue, e.target.checked);
          }}
          color="primary"
          checked={toggled}
          disabled={disabled}
        />
        <Text as="span" variant="bodyMd">
          {toggled ? "On" : "Off"}
        </Text>
      </React.Fragment>
    </div>
  );
};
